import Vue from "vue";
import Login from "./components/Login.vue";
import { GoogleSignin } from "@deltax/google-signin";
import { MicrosoftSignin } from "@deltax/microsoft-signin";
Vue.use(GoogleSignin)
Vue.use(MicrosoftSignin)
let vm = new Vue({
  el: "#login",
  components: {
    Login
  },
  template: '<Login ref="LoginRef" />'

});
window.Login = vm;
